import { Resource, ResourcePack, ResourcePackHeading } from "../../models/Admin";
import { useBoolean } from "../../hooks/useBoolean";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Dropdown,
    Field,
    Input,
    Option,
} from "@fluentui/react-components";
import { ApiValidationError, getEntityWithValidation } from "../../models/ValidatedEntity";
import * as api from "../../utils/api";

type ResourceNew = Omit<Resource, "id">;

export const AdminResourceNew = () => {
    const { packId } = useParams() as { packId: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [packName, setPackName] = useState<string | null>(null);
    const [resource, setResource] = useState<ResourceNew>({ name: "", type: "link", link: "", order: 0 });
    const [resourcePackHeadings, setResourcePackHeadings] = useState<ResourcePackHeading[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);

    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            try {
                const resourcePack = await api.get<ResourcePack>(`api/admin/resource-packs/${packId}`);
                setPackName(resourcePack?.name ?? null);

                setResourcePackHeadings(await api.get<ResourcePackHeading[]>(`api/admin/resource-packs/${packId}/headings`));
            } catch (e) {
                setError(e as Error);
            } finally {
                setActivityMessage(null);
            }
        }
        execute();
    }, [packId]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setActivityMessage("saving...");
        try {
            const updatedEntity = await api.post<ResourceNew, Resource>(`api/admin/resource-packs/${packId}/resources`, resource);
            if ("errors" in updatedEntity) {
                setValidationErrors(updatedEntity.errors);
            } else {
                navigate(`/admin/resource-packs/${packId}/resources/${updatedEntity.id}`);
            }
        } catch (e) {
            setError(e as Error);
        } finally {
            setActivityMessage(null);
        }
    };

    const entityWithValidation = resource && getEntityWithValidation(resource, validationErrors);

    const styles = useDialogStyles();

    const content = entityWithValidation ? (
        <>
            {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
            <Field
                label="Name"
                hint="Name of the resource"
                orientation="vertical"
                required={true}
                validationMessage={entityWithValidation.propertyMessages.name}
            >
                <Input defaultValue={resource.name} onChange={(_, data) => setResource({ ...resource, name: data.value })} />
            </Field>
            <Field
                label="Link"
                hint="Link for the resource"
                orientation="vertical"
                required={true}
                validationMessage={entityWithValidation.propertyMessages.link}
            >
                <Input defaultValue={resource.link} onChange={(_, data) => setResource({ ...resource, link: data.value })} />
            </Field>
            <Field
                label="Order"
                hint="Sort order for the resource - resources are sorted by their order value (lower numbers first)"
                orientation="vertical"
                required={true}
                validationMessage={entityWithValidation.propertyMessages.order}
            >
                <Input
                    defaultValue={resource.order.toString()}
                    type="number"
                    onChange={(_, data) => setResource({ ...resource, order: parseInt(data.value) })}
                />
            </Field>
            <Field
                label="Heading"
                hint="The heading to show the resource under"
                orientation="vertical"
                className={styles.field}
                required={false}
                validationMessage={entityWithValidation.propertyMessages.headingId}
            >
                <Dropdown
                    value={resource.headingId ? resourcePackHeadings.find((h) => h.id === resource.headingId)?.name ?? "" : ""}
                    selectedOptions={[resource.headingId?.toString() ?? ""]}
                    onOptionSelect={(_, data) => {
                        setResource({
                            ...resource,
                            headingId: (data.optionValue ?? "") === "" ? undefined : parseInt(data.optionValue!),
                        });
                    }}
                >
                    <Option text="" value="" />
                    {resourcePackHeadings?.map((g) => (
                        <Option key={g.id} text={g.name} value={g.id.toString()}>
                            {g.name}
                        </Option>
                    ))}
                </Dropdown>
            </Field>
            <div className={styles.actions}>
                <Button appearance="primary" onClick={saveEntity}>
                    Save
                </Button>
                <Button appearance="secondary" onClick={showDiscardConfirmationDialog}>
                    Cancel
                </Button>
                <Dialog open={isDiscardConfirmationDialogOpen}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Discard changes?</DialogTitle>
                            <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                            <DialogActions>
                                <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                    Cancel
                                </Button>
                                <Button appearance="primary" onClick={() => navigate("/admin/resource-packs")}>
                                    Discard
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
        </>
    ) : (
        <></>
    );
    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/resource-packs">resource packs</Link> /{" "}
                <Link to={`/admin/resource-packs/${packId}`}>{packName}</Link> / resources / &lt;new&gt;
            </h1>
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>Error: {error.message}</div>}
            <div className={styles.root}>{content}</div>
        </>
    );
};
