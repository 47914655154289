import { Routes, Route, Outlet } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

import { PageLayout } from "./components/PageLayout";
// import { Profile } from './pages/Profile';
import { Home } from "./pages/Home";

import "./styles/App.css";
import { AdminHome } from "./pages/Admin/AdminHome";
import { AdminResourcePackList } from "./pages/Admin/AdminResourcePackList";
import { loginRequest } from "./authConfig";
import { AdminResourcePackEdit } from "./pages/Admin/AdminResourcePackEdit";
import { AdminResourcePackNew } from "./pages/Admin/AdminResourcePackNew";
import { AdminResourceEdit } from "./pages/Admin/AdminResourceEdit";
import { AdminResourceNew } from "./pages/Admin/AdminResourceNew";
import { UserResourcePackShow } from "./pages/UserResourcePackShow";
import { AdminEventList } from "./pages/Admin/AdminEventList";
import { AdminEventEdit, AdminEventNew } from "./pages/Admin/AdminEventEdit";
import { AdminEventOptionList } from "./pages/Admin/AdminEventOptionList";
import { AdminEventOptionEdit, AdminEventOptionNew } from "./pages/Admin/AdminEventOptionEdit";
import { AdminEventCouponList } from "./pages/Admin/AdminEventCouponList";
import { AdminEventCouponEdit, AdminEventCouponNew } from "./pages/Admin/AdminEventCouponEdit";
import { AdminBookingList } from "./pages/Admin/AdminBookingList";
import { AdminBookingShow } from "./pages/Admin/AdminBookingShow";
import { AdminCustomerList } from "./pages/Admin/AdminCustomerList";
import { AdminCustomerShow } from "./pages/Admin/AdminCustomerShow";
import { AdminAuditEntryList } from "./pages/Admin/AdminAuditEntryList";
import { AdminResourcePackHeadingNew } from "./pages/Admin/AdminResourcePackHeadingNew";
import { AdminResourcePackHeadingEdit } from "./pages/Admin/AdminResourcePackHeadingEdit";

const Pages = () => {
    const authRequest = {
        ...loginRequest,
    };
    return (
        <Routes>
            <Route
                element={
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                        <Outlet />
                    </MsalAuthenticationTemplate>
                }
            >
                <Route path="/admin/resource-packs/:packId/resources/$new" element={<AdminResourceNew />} />
                <Route path="/admin/resource-packs/:packId/resources/:resourceId" element={<AdminResourceEdit />} />
                <Route path="/admin/resource-packs/:packId/headings/$new" element={<AdminResourcePackHeadingNew />} />
                <Route path="/admin/resource-packs/:packId/headings/:headingId" element={<AdminResourcePackHeadingEdit />} />
                <Route path="/admin/resource-packs/$new" element={<AdminResourcePackNew />} />
                <Route path="/admin/resource-packs/:packId" element={<AdminResourcePackEdit />} />
                <Route path="/admin/resource-packs" element={<AdminResourcePackList />} />
                <Route path="/admin/events/:eventSlug/options/$new" element={<AdminEventOptionNew />} />
                <Route path="/admin/events/:eventSlug/options/:optionId" element={<AdminEventOptionEdit />} />
                <Route path="/admin/events/:eventSlug/options" element={<AdminEventOptionList />} />
                <Route path="/admin/events/:eventSlug/coupons/$new" element={<AdminEventCouponNew />} />
                <Route path="/admin/events/:eventSlug/coupons/:couponId" element={<AdminEventCouponEdit />} />
                <Route path="/admin/events/:eventSlug/coupons" element={<AdminEventCouponList />} />
                <Route path="/admin/events/$new" element={<AdminEventNew />} />
                <Route path="/admin/events/:eventSlug" element={<AdminEventEdit />} />
                <Route path="/admin/events" element={<AdminEventList />} />
                <Route path="/admin/bookings/:bookingId" element={<AdminBookingShow />} />
                <Route path="/admin/bookings" element={<AdminBookingList />} />
                <Route path="/admin/customers/:customerId" element={<AdminCustomerShow />} />
                <Route path="/admin/customers" element={<AdminCustomerList />} />
                <Route path="/admin/customers" element={<AdminCustomerList />} />
                <Route path="/admin/audit-entries" element={<AdminAuditEntryList />} />
                <Route path="/admin" element={<AdminHome />} />
            </Route>

            <Route path="/resource-packs/:packId" element={<UserResourcePackShow />} />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: { instance: PublicClientApplication }) => {
    return (
        <MsalProvider instance={instance}>
            <PageLayout>
                <Pages />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;
